// REGISTER
export const POST_REGISTER = "/auth/signup";

// LOGIN
export const POST_LOGIN = "/login";
export const POST_JWT_LOGIN = "/post-jwt-login";
export const POST_PASSWORD_FORGET = "/auth/forgot-password";
export const POST_JWT_PASSWORD_FORGET = "/jwt-forget-pwd";
export const SOCIAL_LOGIN = "/social-login";

// REFRESH TOKEN
export const POST_REFRESH_TOKEN = "/refreshtokens";

// PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile";
export const POST_EDIT_PROFILE = "/user";


// IMAGES
export const POST_CATEGORY_IMAGE='/images?resourceName=categories'
export const POST_BARND_IMAGE='/images?resourceName=brands'
export const POST_PRODUCT_IMAGE='/images?resourceName=products'
export const POST_BANNER_IMAGE='/images?resourceName=banners'

export const DELETE_CATEGORY_IMAGE='/images?resourceName=categories'
export const DELETE_BARND_IMAGE='/images?resourceName=brands'
export const DELETE_PRODUCT_IMAGE='/productimages/'
export const DELETE_BANNER_IMAGE='/images?resourceName=banners'


// Chat
export const GET_CHAT = "/get-chat";
export const ADD_CHAT = "/add-chat";
export const DELETE_CHAT = "/delete-chat";
export const BOOKMARK_CHAT = "/delete-chat";


//Categories
// List View
export const GET_CATEGORY_LIST = "/categories";
export const ADD_CATEGORY_LIST = "/categories";
export const UPDATE_CATEGORY_LIST = "/categories/";
export const DELETE_CATEGORY_LIST = "/categories/";



// City, state, ditricts
// Region
export const GET_STATES = "/states";
export const GET_DISTRICTS = "/districts/searchbyfield?field=stateId&";// /districts/searchbyfield?field=stateId&value=a0c6f669-c16f-4ccc-b274-f8e019105181
export const GET_CITIES = "/cities/searchbyfield?field=districtId&"; // /cities/searchbyfield?field=districtId&value=3054eb22-91ca-488b-b167-eda6c600c9cc


// https://garments-wdop.onrender.com/api/v1/districts/searchbyfield?field=stateId&value=a0c6f669-c16f-4ccc-b274-f8e019105181
// Brands
export const GET_BRANDS = "/brands";
export const ADD_BRANDS = "/brands";
export const UPDATE_BRANDS = "/brands/";
export const DELETE_BRANDS = "/brands/";
export const POST_BRAND_IMAGE='/images?resourceName=brands'

// Products
export const GET_PRODUCT = "/products/";
export const GET_PRODUCT_LIST = "/products?limit=1000&page=1";
export const ADD_PRODUCT = "/products";
export const UPDATE_PRODUCT = "/products/";
export const DELETE_PRODUCT = "/products/";
export const ADD_PRODUCT_IMAGE='/productimages/'
export const GET_PRODUCT_IMAGES='/productimages/searchbyfield?field=productId&value='


// Products Variants
export const GET_PRODUCT_VARIANTS = "/productvariants/";
export const GET_PRODUCT_VARIANT = "/productvariants/";
export const ADD_PRODUCT_VARIANT = "/productvariants/";
export const UPDATE_PRODUCT_VARIANT = "/productvariants/";
export const DELETE_PRODUCT_VARIANT = "/productvariants/";


//FAQ
export const GET_FAQ_LIST = "/faqs";
export const ADD_FAQ_LIST = "/faqs";
export const UPDATE_FAQ_LIST = "/faqs/";
export const DELETE_FAQ_LIST = "/faqs/";


//CMS
export const GET_CMS_LIST = "/cms";
export const ADD_CMS_LIST = "/cms";
export const UPDATE_CMS_LIST = "/cms/";
export const DELETE_CMS_LIST = "/cms/";

//USERS
export const GET_USERS = "/users";
export const ADD_USERS = "/users";
export const UPDATE_USERS = "/users/";
export const DELETE_USERS = "/users/";
export const GET_USERS_BY_ID = "/users/id"
export const GET_SALES_REPRESENTATIVE = "/users/searchbyfield?field=roleName&value=salesRep"

//ROlES
export const GET_ROLES = "/roles";
export const ADD_ROLES = "/roles";
export const UPDATE_ROLES = "/roles/"
export const DELETE_ROLES = "/roles/";
export const GET_ROLES_BY_ID = "users/roleId"
export const GET_USER_ROLES = "/userroles";

//RESOURCES
export const GET_RESOURCES = "/resources";
export const ADD_RESOURCES = "/resources";
export const UPDATE_RESOURCES = "/resources/"
export const DELETE_RESOURCES = "/resources/";

//PERMISSIONS
export const GET_PERMISSIONS = "/permissions";
export const ADD_PERMISSIONS = "/permissions";
export const UPDATE_PERMISSIONS = "/permissions/"
export const DELETE_PERMISSIONS = "/permissions/";


//ANNOUNCEMENT
export const GET_ANNOUNCEMENT_LIST = "/announcements";
export const ADD_ANNOUNCEMENT_LIST = "/announcements";
export const UPDATE_ANNOUNCEMENT_LIST = "/announcements/";
export const DELETE_ANNOUNCEMENT_LIST = "/announcements/";


//BANNERS
export const GET_BANNER_LIST = "/banners/";
export const ADD_BANNER_LIST = "/banners";
export const UPDATE_BANNER_LIST = "/banners/";
export const DELETE_BANNER_LIST = "/banners/";


//COUPONS
export const GET_COUPON_LIST = "/coupons/";
export const ADD_COUPON_LIST = "/coupons";
export const UPDATE_COUPON_LIST = "/coupons/";
export const DELETE_COUPON_LIST = "/coupons/";

//
export const GET_AGE_GROUPS = "/agegroups/";
export const GET_PRODUCT_SIZES = "/productsizes/";
export const GET_SEASONS = "/seasons/";
export const GET_ATTRIBUTES = "/attributes/";
export const GET_ATTRIBUTE_VALUES = "/attributevalues/searchbyfield?field=attributeId&value=";
export const GET_PACKAGINGS = "/productpackagings/";




// Calendar
export const GET_EVENT = "/get-event";
export const ADD_EVENT = "/add-event";
export const UPDATE_EVENT = "/edit-event";
export const DELETE_EVENT = "/delete-event";

// Category
export const GET_CATEGORY = "/get-category";
export const DELETE_CATEGORY = "/delete-category";

// Ecommerce
// Orders
export const GET_ORDERS = "/orders/";
export const GET_ORDER_BY_ID = "/orders/orderdetails/";
export const ADD_ORDERS = "/add-orders";
export const UPDATE_ORDERS_STATUS = "/ordertrackings/";
export const GET_ORDER_STATUS_BY_ID = "/ordertrackings/order/";
export const DELETE_ORDERS = "/delete-orders";
export const GET_MONTHLY_ORDER_COUNTS = "/orders/monthlyordercount"
export const GET_TOTAL_ORDER_COUNTS = "/orders/totalordercount"
export const CANCEL_ORDER = "/orders/cancelorder/";


// Carts
export const GET_CART_SUMMARY = "/carts/cartsummary/";
export const GET_CARTS = "/carts/backend/cartlist";
export const GET_CART_BY_ID = "/carts/backend/cartdetails/user/";

// Wishlist
export const GET_WISHLIST = "/wishlists/backend/wishlist/";
export const GET_WISHLIST_BY_ID = "/wishlists/backend/wishlistdetail/user/";


// Sellers
export const GET_SELLERS = "/categories";
export const ADD_SELLERS = "/add-sellers";
export const UPDATE_SELLERS = "/edit-sellers";
export const DELETE_SELLERS = "/delete-sellers";

// Grid View
export const GET_PRODUCT_GRID = "/get-product-grid";
export const ADD_PRODUCT_GRID = "/add-product-grid";
export const UPDATE_PRODUCT_GRID = "/edit-product-grid";
export const DELETE_PRODUCT_GRID = "/delete-product-grid";

// Overview
export const GET_REVIEW = "/get-review";
export const ADD_REVIEW = "/add-review";
export const UPDATE_REVIEW = "/edit-review";
export const DELETE_REVIEW = "/delete-review";

// HR Management
// Employee List
export const GET_EMPLOYEE = "/get-employee";
export const ADD_EMPLOYEE = "/add-employee";
export const UPDATE_EMPLOYEE = "/edit-employee";
export const DELETE_EMPLOYEE = "/delete-employee";

// Holidays
export const GET_HOLIDAYS = "/get-holidays";
export const ADD_HOLIDAYS = "/add-holidays";
export const UPDATE_HOLIDAYS = "/edit-holidays";
export const DELETE_HOLIDAYS = "/delete-holidays";

// Leaves Manage Employee
export const GET_LEAVE_MANAGE_EMPLOYEE = "/get-leave-manage-employee";

// Leave Manage (HR)
export const GET_LEAVE_MANAGE_HR = "/get-leave-manage-hr";
export const ADD_LEAVE_MANAGE_HR = "/add-leave-manage-hr";
export const UPDATE_LEAVE_MANAGE_HR = "/edit-leave-manage-hr";
export const DELETE_LEAVE_MANAGE_HR = "/delete-leave-manage-hr";

// Attendance
// Attendance (HR)
export const GET_ATTENDANCE = "/get-attendance";

// Main Attendance
export const GET_MAIN_ATTENDANCE = "/get-main-attendance";

// Departments
export const GET_DEPARTMENTS = "/get-departments";
export const ADD_DEPARTMENTS = "/add-departments";
export const UPDATE_DEPARTMENTS = "/edit-departments";
export const DELETE_DEPARTMENTS = "/delete-departments";

// Sales
// Estimates
export const GET_ESTIMATES = "/get-estimates";
export const ADD_ESTIMATES = "/add-estimates";
export const UPDATE_ESTIMATES = "/edit-estimates";
export const DELETE_ESTIMATES = "/delete-estimates";

// Payments
export const GET_PAYMENTS = "/get-payments";

// Expenses
export const GET_EXPENSES = "/get-expenses";
export const ADD_EXPENSES = "/add-expenses";
export const UPDATE_EXPENSES = "/edit-expenses";
export const DELETE_EXPENSES = "/delete-expenses";

// Payroll
// Employee Salary
export const GET_EMPLOYEE_SALARY = "/get-employee-salary";
export const ADD_EMPLOYEE_SALARY = "/add-employee-salary";
export const UPDATE_EMPLOYEE_SALARY = "/edit-employee-salary";
export const DELETE_EMPLOYEE_SALARY = "/delete-employee-salary";

// Notes
export const GET_NOTES = "/get-notes";
export const ADD_NOTES = "/add-notes";
export const UPDATE_NOTES = "/edit-notes";
export const DELETE_NOTES = "/delete-notes";

// Social
// Friends
export const GET_SOCIAL_FRIENDS = "/get-social-friends";

// Events
export const GET_SOCIAL_EVENTS = "/get-social-event";
export const ADD_SOCIAL_EVENTS = "/add-social-event";
export const UPDATE_SOCIAL_EVENTS = "/edit-social-event";
export const DELETE_SOCIAL_EVENTS = "/delete-social-event";

// invoice
export const GET_INVOICE_LIST = "/get-invoice-list"

// Users
// List View
export const GET_USER_LIST = "/get-userlist";
export const ADD_USER_LIST = "/add-userlist";
export const UPDATE_USER_LIST = "/edit-userlist";
export const DELETE_USER_LIST = "/delete-userlist";

// Grid View
export const GET_USER_GRID = "/get-usergrid";
export const ADD_USER_GRID = "/add-usergrid";
export const UPDATE_USER_GRID = "/edit-usergrid";
export const DELETE_USER_GRID = "/delete-usergrid";


//ATTRIBUTES
export const GET_ATTRIBUTE_LIST = "/attributes";
export const ADD_ATTRIBUTE_LIST = "/attributes";
export const UPDATE_ATTRIBUTE_LIST = "/attributes/";
export const DELETE_ATTRIBUTE_LIST = "/attributes/";

//ATTRIBUTE VALUES
export const GET_ATTRIBUTE_VALUE_LIST = "/attributevalues";
export const ADD_ATTRIBUTE_VALUE_LIST = "/attributevalues";
export const UPDATE_ATTRIBUTE_VALUE_LIST = "/attributevalues/";
export const DELETE_ATTRIBUTE_VALUE_LIST = "/attributevalues/";

//PRODUCT SIZE
export const GET_PRODUCT_SIZE = "/productsizes";
export const ADD_PRODUCT_SIZE = "/productsizes";
export const UPDATE_PRODUCT_SIZE = "/productsizes/";
export const DELETE_PRODUCT_SIZE = "/productsizes/";

//DISCTRICT LIST
export const GET_DISTRICT_LIST = "/districts?limit=1000&page=1";
export const ADD_DISTRICT_LIST = "/districts";
export const UPDATE_DISTRICT_LIST = "/districts/";
export const DELETE_DISTRICT_LIST = "/districts/";

//STATE LIST
export const GET_STATE_LIST = "/states?limit=1000&page=1";

// CITY LIST 
export const GET_CITY_LIST = "/cities?limit=1000&page=1";
export const ADD_CITY_LIST = "/cities";
export const UPDATE_CITY_LIST = "/cities/";
export const DELETE_CITY_LIST = "/cities/";


// User Permissions
export const GET_USER_PERMISSIONS = "/permissions/role/";


// user Menu
export const GET_MENUS = "/menus/";
export const ADD_MENU = "/menus/";
export const ADD_ROLE_MENU = "/rolemenus/";
export const GET_MENUS_BY_ROLE = "/rolemenus/role/";



// unverified docs
export const GET_UNVERIFIED_DOCS = "/businessdocs/filterdoc/All";

export const GET_UNVERIFIED_BUSINESS = "/businesses/status/Unverified";
export const GET_BUSINESS_INFO = "/businesses/";
export const GET_BUSINESS_DOCS = "/businessdocs/searchbyfield?field=businessId&value=";

export const UPDATE_BUSINESS_INFO = "/businesses/";
export const UPDATE_DOC_STATUS = "/businessdocs/verifydoc/";
export const UPDATE_DOC_STATUS_BY_BUSINESS = "/businesses/status/";

