import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from 'react-router-dom';
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
import Select, { SelectInstance } from "react-select";
import {
  updateDocStatus,
  getBusinessById,
  getStates,
  getDistricts,
  getCities,
  getBusinessDocs,
  updateDocStatusByBusiness,
  updateBusiness,
} from "../../../helpers/backend_helper";
import { Pencil, Search, Trash2, Plus, Link2, Link2Icon } from "lucide-react";

import { Common } from "helpers/common";
import { ChevronDown, ChevronUp, Loader2 } from "lucide-react";
import { PendingBusinessType } from "types/PendingBusinessType";
import { BusinessType } from "types/BusinessType";
import { RegionType } from "types/RegionType";
import Collapse from "Common/Components/Collapse/Collapse";
import BreadCrumb from "Common/BreadCrumb";
import { ToastContainer } from "react-toastify";
import { BusinessDocType } from "types/BusinessDocType";
import moment from "moment";
import { use } from "i18next";
type Props = { onHide: () => void; data: PendingBusinessType; onSave: (result: any) => void };

export const UnverifiedBusinessDetails = () => {
  const [businessInfo, setBusinessInfo] = useState<BusinessType>();
  const [businessDocs, setBusinessDocs] = useState<BusinessDocType[]>([]);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isBDocSaving, setIsBDocSaving] = useState<boolean>(false);
  const commonHelper = new Common();
  const districtRef = useRef<SelectInstance<RegionType> | null>(null)
  const cityRef = useRef<SelectInstance<RegionType> | null>(null)

  const [states, setStates] = useState<RegionType[]>([]);
  const [districts, setDistricts] = useState<RegionType[]>([]);
  const [cities, setCities] = useState<RegionType[]>([]);

  const [selectedFiles, setSelectedFiles] = React.useState<any>([])
  const [selectedState, setSelectedState] = useState<any>();
  const [selectedCity, setSelectedCity] = useState<any>();
  const [selectedDistrict, setSelectedDistrict] = useState<any>();

  const { businessId } = useParams();
  console.log('businessId', businessId)

  useEffect(() => {
    loadData();
  }, []);

useEffect(() => {
  if (businessInfo?.id) {
     loadStates();
  }
  console.log('businessInfo?.id', businessInfo?.id);
}, [businessInfo?.id]);


  const loadData = async () => {
    await loadBusinessInfo();
    await loadBusinessDocs();
  }

  const loadStates = async () => {
    if (states.length === 0) {

      let response = await getStates();
      console.log('states', response)
      console.log('businessInfo', businessInfo);

      if (response.success) {
        const items = [{ value: '', label: 'Select' }, ...(response.data as any[]).map(item => ({ value: item.id, label: item.name }))]
        setStates(items);
        console.log('businessInfo?.stateId', businessInfo?.stateId);
        if (businessInfo?.stateId) {
          setSelectedState(items?.find(i => i.value == businessInfo?.stateId));
        }
      }

      if (businessInfo?.id) {
        await loadDistrict(businessInfo.stateId.toString());
        await loadCities(businessInfo.districtId.toString());
      }
    }
  }

  const loadDistrict = async (stateId: string) => {
    if (!stateId)
      return;

    var params = 'value=' + stateId;
    console.log(params);
    try {
      let response = await getDistricts(params)
      console.log('districts', response)

      if (response.success) {
        const items = [{ value: '', label: 'Select' }, ...(response.data as any[]).map(item => ({ value: item.id, label: item.name }))]
        setDistricts(items);
        if (businessInfo?.districtId) {
          setSelectedDistrict(items?.find(i => i.value == businessInfo?.districtId));
        }
      }
    } catch (err) {
      console.log(err);
      commonHelper.showErrorMessage('No district found for selected state.')
    }
  }

  const loadCities = async (districtId: string) => {
    if (!districtId)
      return;

    var params = 'value=' + districtId;
    try {

      let response = await getCities(params)
      console.log('cities', response)

      if (response.success) {
        const items = [{ value: '', label: 'Select' }, ...(response.data as any[]).map(item => ({ value: item.id, label: item.name }))]
        setCities(items);
        if (businessInfo?.cityId) {
          setSelectedCity(items?.find(i => i.value == businessInfo?.cityId));
        }
      }
    } catch (err) {
      console.log(err);
      commonHelper.showErrorMessage('No city found for selected district.')
    }
  }

  const handleStateSelection = async (item: any) => {
    districtRef?.current?.clearValue();
    setSelectedState(item);
    setDistricts([]);
    setSelectedDistrict({});
    validation.setFieldValue(
      'stateId',
      item.value
    );

    loadDistrict(item.value)
  }

  const handleDistrictSelection = async (item: any) => {
    if (item) {
      cityRef?.current?.clearValue();
      setSelectedDistrict(item);
      setSelectedCity({} as RegionType);
      setCities([]);
      validation.setFieldValue(
        'districtId',
        item.value
      )
      loadCities(item.value);
    }
  }

  const handleCitySelection = (item: any) => {
    setSelectedCity(item);

    if (item) {
      console.log('selected state', item.value)
      validation.setFieldValue('cityId', item.value);
    }
  }


  async function loadBusinessInfo() {
    try {
      const response = await getBusinessById(businessId ?? '0');
      const responseData = response.data as BusinessType[];
      setBusinessInfo(responseData[0]);
      console.log(responseData[0]);


    } catch (error) {
      console.error("Error loading roles", error);
    }
  }


  async function loadBusinessDocs() {
    try {
      const response = await getBusinessDocs(businessId ?? '0');
      const responseData = response.data as BusinessDocType[];

      const sortedData = responseData.sort((a, b) => a.id - b.id);

      setBusinessDocs(sortedData);
      console.log('business dcos', sortedData);

    } catch (error) {
      console.error("Error loading roles", error);
    }
  }



  // validation for Business Information
  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: (businessInfo && businessInfo?.id) || "0",
      // documentRejectedReason: (businessInfo && businessInfo?.documentRejectedReason) || "",
      // documentVerified: true,

      userId: (businessInfo && businessInfo?.userId) || "0",
      businessName: (businessInfo && businessInfo?.businessName) || "",
      gstNo: (businessInfo && businessInfo?.gstNo) || "",
      ownerName: (businessInfo && businessInfo?.ownerName) || "",
      businessEstablishmentYear: (businessInfo && businessInfo?.businessEstablishmentYear) || "",
      businessType: (businessInfo && businessInfo?.businessType) || "",
      addressLine1: (businessInfo && businessInfo?.addressLine1) || "",
      addressLine2: (businessInfo && businessInfo?.addressLine2) || "",
      landmark: (businessInfo && businessInfo?.landmark) || "",
      stateId: (businessInfo && businessInfo?.stateId) || "",
      districtId: (businessInfo && businessInfo?.districtId) || "",
      cityId: (businessInfo && businessInfo?.cityId) || "",
      cityName: (businessInfo && businessInfo?.cityName) || "",
      countryId: (businessInfo && businessInfo?.countryId) || 1,
      pinCode: (businessInfo && businessInfo?.pinCode) || "",
      businessLogo: (businessInfo && businessInfo?.businessLogo) || "",
      businessDescription: (businessInfo && businessInfo?.businessDescription) || "",
      businessTagLine: (businessInfo && businessInfo?.businessTagLine) || "",
      contactPerson: (businessInfo && businessInfo?.contactPerson) || "",
      contactPhone: (businessInfo && businessInfo?.contactPhone) || "",
      contactEmail: (businessInfo && businessInfo?.contactEmail) || "",
      website: (businessInfo && businessInfo?.website) || "",
      docStatus: (businessInfo && businessInfo?.docStatus) || "",
    },
    validationSchema: Yup.object({
      documentRejectedReason: Yup.string(),
    }),

    onSubmit: async (values: any) => {
      setIsSaving(true);
      console.log(values);
      try {
        var result = await updateBusiness(values);

        commonHelper.showSuccessMessage(`Business info has been successfully updated.`);
        setIsSaving(false);

        console.log(result.data.rows[0]);
      } catch (error: any) {
        console.error("API-Error", error.response.data);
        commonHelper.showErrorMessage(error.response.data.message);
        setIsSaving(false);
      }
    },
  });


  const handleDocStatusChange = (index: number, value: string) => {
    console.log("handleDocStatusChange", index, value);
    const updatedDoc = [...businessDocs];
    updatedDoc[index].documentVerified = value === 'true' ? true : false;
    setBusinessDocs(updatedDoc); // Update the state
  };

  const handleDocCommentChange = (index: number, value: string) => {
    console.log("handleDocCommentChange", index, value);
    const updatedDoc = [...businessDocs];
    updatedDoc[index].documentRejectedReason = value;
    setBusinessDocs(updatedDoc); // Update the state
  };


  const handleDocStatusUpdate = async (index: number) => {
    console.log('Updated record:', businessDocs[index]);
    let error = '';

    if (!businessDocs[index].documentRejectedReason || businessDocs[index].documentRejectedReason.length < 2) {
      error = 'Please enter status comment.';
    }

    if (businessDocs[index].documentVerified === null) {
      error = 'Please select a status.';
    }

    const updatedDoc = [...businessDocs];
    updatedDoc[index].error = error;
    setBusinessDocs(updatedDoc); // Update the state

    if (!error) {
      try {
        const result = await updateDocStatus(businessDocs[index]);
        console.log(result.data.rows[0]);
        commonHelper.showSuccessMessage(`Document status has been successfully updated.`);
      } catch (error: any) {
        console.error("API-Error", error.response.data);
        commonHelper.showErrorMessage(error.response.data.message);
      }
    }
  };


  // validation for Business Docs
  const validationBDoc: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: businessId,
      docStatus: (businessInfo && businessInfo?.docStatus) || "",
      documentRejectedReason: "",
      documentVerified: true,
    },
    validationSchema: Yup.object({
      documentRejectedReason: Yup.string()//.required("Status comment is required."),
    }),

    onSubmit: async (values: any) => {
      setIsBDocSaving(true);
      console.log('values', values);
      try {

        var result = await updateDocStatusByBusiness(values);

        commonHelper.showSuccessMessage(`Business document status has been successfully updated.`);
        // console.log(result.data.rows[0]);
      } catch (error: any) {
        console.error("API-Error", error.response.data);
        commonHelper.showErrorMessage(error.response.data.message);
        setIsBDocSaving(false);
      }
    },
  });


  return (
    <React.Fragment>
      <BreadCrumb title='Unverified Business' pageTitle='Products' />
      <ToastContainer closeButton={false} limit={1} />
      <div>
        <div className="grid grid-cols-1 xl:grid-cols-12 gap-x-5">
          <div className="xl:col-span-6">
            <div className="card">
              <h4 className="text-15" style={{ marginLeft: 20, marginTop: 10 }} >Business Information</h4>
              <div className="ltr:ml-auto rtl:mr-auto shrink-0">
              </div>
              <div className="card-body">

                <form action="#!" onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}>
                  <div className="grid grid-cols-1 gap-5 xl:grid-cols-12">
                    <div className="xl:col-span-4">
                      <label htmlFor="businessName" className="text-base font-medium">Business Name<span className='redColor'>*</span></label>
                      <input type="text" id="businessName"
                        className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                        placeholder="Business Name"
                        name="businessName"

                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.businessName || ""}
                      />
                      {validation.touched.businessName && validation.errors.businessName ? (
                        <p className="text-red-400">{validation.errors.nabusinessNameme}</p>
                      ) : null}
                    </div>


                    <div className="xl:col-span-4">
                      <label htmlFor="ownerName" className="inline-block mb-1 text-base font-medium">Owner Name<span className='redColor'>*</span></label>
                      <input id="ownerName" className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                        placeholder="Owner Name"
                        name="ownerName"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.ownerName || ""}
                      />
                      {validation.touched.ownerName && validation.errors.ownerName ? (
                        <p className="text-red-400">{validation.errors.ownerName}</p>
                      ) : null}
                    </div>
                    <div className="xl:col-span-4">
                      <label htmlFor="gstNo" className="inline-block mb-1 text-base font-medium">GST No<span className='redColor'>*</span></label>
                      <textarea id="gstNo"
                        className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                        placeholder="GST No"
                        name="gstNo"
                        rows={1}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.gstNo || ""}
                      />
                      {validation.touched.gstNo && validation.errors.gstNo ? (
                        <p className="text-red-400">{validation.errors.gstNo}</p>
                      ) : null}
                    </div>


                    <div className="xl:col-span-6">
                      <label htmlFor="addressLine1" className="inline-block mb-1 text-base font-medium">Address 1<span className='redColor'>*</span></label>
                      <input id="addressLine1" className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" placeholder="Address1"
                        name="addressLine1"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.addressLine1 || ""}
                      />
                      {validation.touched.addressLine1 && validation.errors.addressLine1 ? (
                        <p className="text-red-400">{validation.errors.addressLine1}</p>
                      ) : null}
                    </div>
                    <div className="xl:col-span-6">
                      <label htmlFor="addressLine2" className="inline-block mb-1 text-base font-medium">Address 2<span className='redColor'>*</span></label>
                      <input id="addressLine2" className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" placeholder="Address2"
                        name="addressLine2"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.addressLine2 || ""}
                      />
                      {validation.touched.addressLine2 && validation.errors.addressLine2 ? (
                        <p className="text-red-400">{validation.errors.addressLine2}</p>
                      ) : null}
                    </div>

                    <div className="xl:col-span-4">
                      <label htmlFor="stateId" className="inline-block mb-2 text-base font-medium">State<span className='redColor'>*</span></label>

                      <Select
                        className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                        defaultValue={selectedState}
                        options={states}
                        isSearchable={true} // To disable search
                        name="stateId"
                        id="stateId"
                        value={selectedState}
                        onChange={e => { handleStateSelection(e) }}
                      />


                    </div>
                    <div className="xl:col-span-4">
                      <label htmlFor="districtId" className="inline-block mb-2 text-base font-medium">District<span className='redColor'>*</span></label>
                      <Select
                        className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                        options={districts}
                        ref={districtRef}
                        isSearchable={true} // To disable search
                        name="districtId"
                        id="districtId"
                        value={selectedDistrict}
                        onChange={e => { handleDistrictSelection(e) }}
                      />


                    </div>
                    <div className="xl:col-span-4">
                      <label htmlFor="cityId" className="inline-block mb-2 text-base font-medium">City<span className='redColor'>*</span></label>
                      <Select
                        className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                        options={cities}
                        ref={cityRef}
                        isSearchable={true} // To disable search
                        name="cityId"
                        id="cityId"
                        value={selectedCity}
                        onChange={e => { handleCitySelection(e) }}
                      />

                    </div>
                    <div className="xl:col-span-4">
                      <label htmlFor="pinCode" className="inline-block text-base font-medium">Pin Code<span className='redColor'>*</span></label>
                      <input id="pinCode" type="number" maxLength={1} className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                        placeholder="Pin Code"
                        name="pinCode"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.pinCode || ""}
                      />
                      {validation.touched.pinCode && validation.errors.pinCode ? (
                        <p className="text-red-400">{validation.errors.pinCode}</p>
                      ) : null}
                    </div>
                    <div className="xl:col-span-4">
                      <label htmlFor="contactPerson" className="inline-block mb-1 text-base font-medium">Contact Person<span className='redColor'>*</span></label>
                      <input id="contactPerson" className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                        placeholder="Contact Person"
                        name="contactPerson"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.contactPerson || ""}
                      />
                      {validation.touched.contactPerson && validation.errors.contactPerson ? (
                        <p className="text-red-400">{validation.errors.contactPerson}</p>
                      ) : null}
                    </div>
                    <div className="xl:col-span-4">
                      <label htmlFor="contactPhone" className="inline-block text-base font-medium">Phone NO.<span className='redColor'>*</span></label>
                      <input id="contactPhone" type="number" maxLength={1} className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" placeholder="phone"
                        name="contactPhone"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.contactPhone || ""}
                      />
                      {validation.touched.contactPhone && validation.errors.contactPhone ? (
                        <p className="text-red-400">{validation.errors.contactPhone}</p>
                      ) : null}
                    </div>


                    <div className="xl:col-span-12">

                      <div className="flex justify-end gap-2 mt-4">
                        <button type="reset" data-modal-close="addDocuments" className="text-red-500 transition-all duration-200 ease-linear bg-white border-white btn hover:text-red-600 focus:text-red-600 active:text-red-600 dark:bg-zink-500 dark:border-zink-500" >Cancel</button>

                        {!isSaving ?
                          <button type="submit" className="text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
                            {"Update"}
                          </button>
                          : <button type="button" disabled className="flex items-center text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
                            <Loader2 className="size-4 ltr:mr-2 rtl:ml-2 animate-spin" />
                            Saving...
                          </button>
                        }
                      </div>
                    </div>
                  </div>

                </form>

              </div>
            </div>

            <div className="card">
              <h4 className="text-15" style={{ marginLeft: 20, marginTop: 10 }} >Business Documents Status</h4>
              <div className="ltr:ml-auto rtl:mr-auto shrink-0">
              </div>
              <div className="card-body">
                <form action="#!!" onSubmit={(e) => {
                  e.preventDefault();
                  validationBDoc.handleSubmit();
                  return false;
                }}>
                  <div className="grid grid-cols-1 gap-5 xl:grid-cols-12">
                    <div className="xl:col-span-12">
                      <div className="flex flex-wrap gap-2">
                        <label htmlFor="docStatus" className="inline-block text-base font-medium">Document Status<span className='redColor'>*</span></label>


                        <div className="flex items-center gap-2">
                          <input id="rdbBDocsApproved" data-testid="dbDocsApproved" name="docBStatus"
                            className="size-4 border rounded-full appearance-none cursor-pointer bg-slate-100 border-slate-200 dark:bg-zink-600 dark:border-zink-500 checked:bg-green-500 checked:border-green-500 dark:checked:bg-green-500 dark:checked:border-green-500"
                            type="radio"
                            value="Verified"
                            onChange={validationBDoc.handleChange}
                            defaultChecked={validationBDoc?.values.docStatus === "Verified"}
                          />
                          <label htmlFor="rdbBDocsApproved" className="align-middle">
                            Approve Docs {validationBDoc.values.docStatus}
                          </label>
                        </div>

                        <div className="flex items-center gap-2">
                          <input id="rdbBDocsRejected" name="docBStatus" className="size-4 border rounded-full appearance-none cursor-pointer bg-slate-100 border-slate-200 dark:bg-zink-600 dark:border-zink-500 checked:bg-orange-500 checked:border-orange-500 dark:checked:bg-orange-500 dark:checked:border-orange-500"
                            type="radio"
                            value="Rejected"
                            onChange={validationBDoc.handleChange}
                            defaultChecked={validationBDoc?.values.docStatus !== "Verified"}
                          />
                          <label htmlFor="rdbBDocsRejected" className="align-middle">
                            Reject Docs
                          </label>
                        </div>
                      </div>
                    </div>
                    {/* <div className="xl:col-span-12">
                      <textarea
                        className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                        id="documentRejectedReason"
                        name="documentRejectedReason"
                        rows={1}
                        placeholder="Status Comments"
                        onChange={validationBDoc.handleChange}
                        onBlur={validationBDoc.handleBlur}
                        value={validationBDoc.values.documentRejectedReason || ""}
                      />
                      {validationBDoc.touched.documentRejectedReason && validationBDoc.errors.documentRejectedReason ? (
                        <p className="text-red-400">{validationBDoc.errors.documentRejectedReason}</p>
                      ) : null}
                    </div> */}
                    <div className="xl:col-span-12">
                      <div className=" flex justify-end gap-2 " style={{ justifyItems: 'flex-end', alignItems: 'flex-end', alignContent: 'flex-end' }}>
                        <button
                          type="reset"
                          data-modal-close="addDocuments"
                          className="text-red-500 transition-all duration-200 ease-linear bg-white border-white btn hover:text-red-600 focus:text-red-600 active:text-red-600 dark:bg-zink-500 dark:border-zink-500"
                        >
                          Cancel
                        </button>

                        {!isBDocSaving ? (
                          <button
                            type="submit"
                            className="text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                          >
                            {"Update"}
                          </button>

                        ) : (
                          <button
                            type="button"
                            disabled
                            className="flex items-center text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                          >
                            <Loader2 className="size-4 ltr:mr-2 rtl:ml-2 animate-spin" />
                            Saving...
                          </button>
                        )}
                      </div>
                    </div>

                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className="xl:col-span-6">
            {businessDocs?.map((item, index) => {
              return (
                <div key={'business-doc-table-' + index} className="card">
                  <div className="card-body">
                    <table style={{ width: '100%', border: 1 }}  >
                      <thead>
                        <tr >
                          <td style={{ width: '35%', fontWeight: 'bold', color: 'gray' }}>Doc Type</td>
                          <td style={{ width: '25%', fontWeight: 'bold', color: 'gray' }}>Doc Number</td>
                          <td style={{ width: '25%', fontWeight: 'bold', color: 'gray' }}>Submitted On</td>
                          <td style={{ width: '15%', fontWeight: 'bold', color: 'gray', paddingLeft: 50 }} className=" flex justify-end ">Url</td>
                        </tr></thead>
                      <tbody>
                        <tr>
                          <td style={{ width: '35%' }}> {item?.documentName}</td>
                          <td style={{ width: '25%' }}> {item.documentNumber}</td>
                          <td style={{ width: '25%' }}>{moment(item.documentVerifiedOn).format("DD/MM/YY")}</td>
                          <td style={{ width: '15%', paddingLeft: 50 }} className=" flex justify-end ">
                            <Link
                              to={item?.documentUrl}
                              target="_blank"
                              className="flex items-center justify-center size-8 transition-all duration-200 ease-linear rounded-md text-green-500 bg-green-100 hover:text-white hover:bg-green-500 dark:bg-green-500/20 dark:hover:bg-green-500"
                            >
                              <Link2Icon className="inline-block size-4 ltr:mr-1 rtl:ml-1" />{" "}
                            </Link>

                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="flex flex-wrap gap-2">
                              <label
                                htmlFor="documentRejectedReason"
                                className="inline-block mb-2 text-base font-medium"
                                style={{ fontWeight: 'bold', color: 'gray' }}
                              >
                                Status
                              </label>
                              <div className="flex items-center gap-2">
                                <input id={"rdbDocApprove" + index} name={"InlineRadio" + index} className="size-4 border rounded-full appearance-none cursor-pointer bg-slate-100 border-slate-200 dark:bg-zink-600 dark:border-zink-500 checked:bg-green-500 checked:border-green-500 dark:checked:bg-green-500 dark:checked:border-green-500"
                                  type="radio" value={"true"}
                                  checked={item.documentVerified}
                                  onChange={(e) => handleDocStatusChange(index, e.target.value)}
                                />
                                <label htmlFor={"rdbDocApprove" + index} className="align-middle">
                                  Approve
                                </label>
                              </div>

                              <div className="flex items-center gap-2">
                                <input id={"rdbDocReject" + index} name={"InlineRadio" + index} className="size-4 border rounded-full appearance-none cursor-pointer bg-slate-100 border-slate-200 dark:bg-zink-600 dark:border-zink-500 checked:bg-orange-500 checked:border-orange-500 dark:checked:bg-orange-500 dark:checked:border-orange-500"
                                  type="radio" value={"false"}
                                   checked={item.documentVerified!=null && !item.documentVerified}
                                  onChange={(e) => handleDocStatusChange(index, e.target.value)}
                                />
                                <label htmlFor={"rdbDocReject" + index} className="align-middle">
                                  Reject
                                </label>
                              </div>
                            </div>
                          </td>
                          <td colSpan={2}>
                            <textarea
                              className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                              id={"documentRejectedReason" + index}
                              name={"documentRejectedReason" + index}
                              rows={1}
                              defaultValue={item.documentRejectedReason??""}
                              onChange={(e) => handleDocCommentChange(index, e.target.value)}
                              placeholder="Status Comments" />
                          </td>
                          <td>
                            <div className=" flex justify-end " >
                              <button
                                key={"documentRejectedReason" + index}
                                type="button"
                                className="text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                                onClick={() => handleDocStatusUpdate(index)}
                              > {"Update "}
                              </button>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={4}>
                            {item.error ? (<p className="text-red-400">{item.error}</p>) : null}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              )
            })
            }

          </div>

          <div className="xl:col-span-6">


          </div>

        </div>
      </div>
    </React.Fragment>
  );
};
