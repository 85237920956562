import TableContainer from 'Common/TableContainer';
import { addProduct, addProductVariant, getBrands, getCategoryList, getPackagings, getProductList, getProductSizes, getSeasons } from 'helpers/backend_helper';
import { Search } from 'lucide-react';
import React, { useEffect, useMemo, useState } from 'react';
import * as XLSX from 'xlsx';


export const BulkUploadProduct = () => {


  const [categories, setCategories] = useState<any[]>([]);
  const [productSizes, setProductSizes] = useState<any[]>([]);
  const [seasons, setSeasons] = useState<any[]>([]);
  const [packagings, setPackagings] = useState<any[]>([]);
  const [excelData, setExcelData] = useState<any>(null);
  const [productData, setProductData] = useState<any>();
  const [brands, setBrands] = useState<any[]>([]);
  const [keys, setKeys] = useState<any[]>([]);
  const [productList, setProductList] = useState([]);




  useEffect(() => {
    loadCategories();
    loadBrands();
    loadProductSizes();
    loadSeasons();
    loadPackagings();
    loadProducts();
  }, [])


  async function loadProducts() {
    try {
      if (productList.length === 0) {

        let response = await getProductList();
        if (response.success) {
          console.log('product', response.data);
          setProductList(response.data as never[]);
        }
      }
    } catch (error) {

    } finally {
    }
  }

  const loadBrands = async () => {
    if (brands.length === 0) {
      const response = await getBrands();
      if (response.success) {
        const brandItems = [{ value: '', label: 'Select', discount: 0 }, ...response.data.map((item: any) => ({ value: item.id, label: item.name, discount: item.discount }))];
        setBrands(brandItems);
      }
    }
  };


  const loadCategories = async () => {
    if (categories.length === 0) {
      try {
        const response = await getCategoryList();
        const categoryItems = response.data.map((item: any) => ({ value: item.id, label: item.name, }));
        setCategories([{ value: '', label: 'Select' }, ...categoryItems,]);
      } catch {
        // ignore error
      }
    }
  }

  const loadProductSizes = async () => {
    if (productSizes.length === 0) {
      try {
        const response = await getProductSizes();
        const prodSizes = response.data.map(({ id, name }) => ({ value: id, label: name }));
        setProductSizes(prodSizes);

      } catch { }
    }
  };


  const loadSeasons = async () => {
    if (seasons.length === 0) {
      try {
        const response = await getSeasons();
        const items = [{ value: '', label: 'Select' }, ...response.data.map(({ id, name }) => ({ value: id, label: name }))];
        setSeasons(items);

      } catch { }
    }
  };


  const loadPackagings = async () => {
    if (packagings.length === 0) {
      try {
        const response = await getPackagings();
        const items = response.data.map((item: any) => ({
          value: item.id,
          label: item.name,
        }));
        setPackagings([{ value: '', label: 'Select' }, ...items]);

      } catch { }
    }
  };



  const handleFileUpload = (e: any) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event: any) => {
      const workbook = XLSX.read(event.target.result, { type: 'binary' });
      const sheetName = workbook.SheetNames[4];
      const sheet = workbook.Sheets[sheetName];
      const sheetData = XLSX.utils.sheet_to_json(sheet);

      setExcelData(sheetData);

      const keys = sheetData.length > 0 ? Object.keys(sheetData[0] as any) : [];
      setKeys(keys);

      // setTimeout(() => {
      //   //const keys = Object.keys(sheetData.length ? excelData[0] : {});
      //   const keys = sheetData.length > 0 ? Object.keys(sheetData[0] as any) : [];
      //   setKeys(keys);

      // }, 1000)

    };

    reader.readAsBinaryString(file);
  };

  const handleImport = async () => {
    if (excelData) {
      // loop thru the excel data and add new fields
      console.log('categories', categories);

      excelData.forEach(async (item: any) => {
        //const item = excelData[0];

        const brand = brands.find(({ label }) => label?.toUpperCase() === (item['BrandName'] || '').toUpperCase());
        const category = categories.find(({ label }) => label?.toUpperCase() === (item['Category'] || '').toUpperCase());
        const season = seasons.find(({ label }) => label?.toUpperCase() === (item['Season'] || '').toUpperCase());
        const size = productSizes.find(({ label }) => label?.toUpperCase().trim() === (item['SizeGroups'] || '').toUpperCase().trim());

        item.brandId = brand?.value || 0;
        item.categoryId = category?.value || 0;
        item.seasonId = season?.value || 1;
        item.sizeId = size?.value || 0;

        item.AvailableFrom = '08/15/2024';
        item.AvailableTo = '08/15/2025';
        item.productColor = ["#eee"];

        item.packagingId = 1;
        item.productTags = ["Garments Hub", "Summer Cloths"];

        item.attributes = [
          {
            "attribute": {
              "value": "1",
              "label": "Material"
            },
            "attributeValue": {
              "value": "8",
              "label": "Cotton"
            }
          }
        ];
        item.publish = true;
        item.isActive = true;
        console.log('item1', item);

        const unitPrice = item['UnitPrice'] || 0;
        const discountPercent = item['Discount'] || 0;
        const discountValue = Math.round(((unitPrice / 100) * discountPercent) * 100) / 100.00;

        item.Gender = ['MALE', 'BOYS', 'BOY'].includes(item.Gender.toUpperCase()) ? 'Boys' : ['FEMALE', 'GIRLS', 'GIRL'].includes(item.Gender.toUpperCase()) ? 'Girls' : 'All';

        const product = {
          name: item.ProductName + ' ' + item.SKU,
          description: item.Description,
          sku: item.SKU,
          brandId: item.brandId,

          gender: item.Gender,
          categoryId: item.categoryId,

          availableFromDate: item.AvailableFrom,
          availableToDate: item.AvailableTo,

          productColor: [
            '#eee'
          ],
          seasonId: item.seasonId,
          packOf: item.Pack,
          mrp: 0.00,
          packagingId: 1,
          productTags: [
            'Garments Hub',
            'Summer Cloths'
          ],

          attributes: [
            {
              attribute: {
                value: 1,
                label: "Material"
              },
              attributeValue: {
                value: 8,
                label: "Cotton"
              }
            }
          ],
          publish: true,
          isActive: true

        };

        let productVariant = {
          "productId": 0,
          "sizeId": item.sizeId,
          "color": ["#eee"],
          "packOf": item.Pack || '',
          "unitPrice": item.UnitPrice || 1.00,
          "mrp": 0.00,
          "margin": 0.00,
          "discountPercent": discountPercent,
          "discountValue": discountValue,
          "inventory": 1000,
          "isActive": true
        };

        // check if the product already exists in ProductList

        const existingProduct = productList.find((p: any) => p.name === product.name);
        const prodId = existingProduct && existingProduct['id'];

        if (!prodId) {
          try {
            const response = await addProduct(product);
            console.log('Product-response', response);
            const newProductId = response.data?.rows[0]?.id;
            productVariant.productId = newProductId;
            saveProductVariants(productVariant);

          } catch (error: any) {
            console.log('Product-Error-Response', error.response);
            console.log('Error', productVariant, product);
          }
        } else {
          productVariant.productId = prodId;
          saveProductVariants(productVariant);
        }
      });

      console.log('excelData', excelData);
    }
  }


  const saveProductVariants = async (productVariant: any) => {
    try {
      const response = await addProductVariant(productVariant);
      console.log('ProductVariant-response', response);
    } catch (error: any) {
      console.log('ProductVariant-Error-Response', error.response);
    }
  }

 

  return (
    <div>
      <input type="file" onChange={handleFileUpload} />

      <button type="submit" className="text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
        onClick={() => { handleImport(); }}
      >
        {"Import"}
      </button>
      {excelData && (
        <div>
          <h2>Imported Data:</h2>

            <div className="App">
            {excelData.length > 0 && (
              <table border={1}>
                <thead>
                  <tr>
                    {keys.map((item, idx) => (
                      <th key={'th-'+idx}>{item}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {excelData.map((item: string, idx: number) => (
                    <tr key={'td-'+idx}>
                      {keys.map((key, idx) => (
                        <td>{item[key]}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>

        </div>

      )}
    </div>
  );
}
